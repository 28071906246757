import Appsections from '../../pages/app-section/app-section';
import AppsectionsCreate from '../../pages/app-section/create/create-edit';
import AutocompleteArray from '../../pages/sinader/autocomplete-array';
import BZeroReports from '../../pages/bzero-reports/report';
import CalendarPage from '../../pages/settings/calendar/calendar';
import ChangePass from '../../pages/my-profile/components/change-password';
import Client from '../../pages/settings/client/client';
import ClientCreate from '../../pages/settings/client/create/create-edit';
import ClientProperties from '../../pages/settings/client/client-properties/client-properties';
import Company from '../../pages/settings/gestor/gestor';
import CompanyCreate from '../../pages/settings/gestor/create/create-edit';
import ControlPanel from '../../pages/control-panel/control-panel';
import CreateQR from '../../pages/settings/qr/create-qr';
import QR from '../../pages/settings/qr/qr';
import Data from '../../pages/settings/data/typology';
import DataCenter from '../../pages/data-center/data-center';
import DataCreate from '../../pages/settings/data/create/create-edit';
import DefaultValues from '../../pages/sinader/default-values';
import EditProfile from '../../pages/my-profile/components/edit-profile';
import Evolution from '../../pages/data-center/evolution/evolution';
import GarbageControl from '../../pages/data-center/garbage-control/garbage-control';
import Help from '../../pages/help/help';
import Invitations from '../../pages/settings/invites/invites';
import InvitationsCreate from '../../pages/settings/invites/create/create';
import MaterialKind from '../../pages/settings/material-kind/material-kind';
import MaterialKindCreate from '../../pages/settings/material-kind/create/create-edit';
import Material from '../../pages/settings/material/material';
import MaterialCreate from '../../pages/settings/material/create/create-edit';
import MontlyClosure from '../../pages/montly-closure/montly-closure';
import MyProfile from '../../pages/my-profile/my-profile';
import ProfileSelection from '../../pages/profile-selection/profile-selection';
import Program from '../../pages/program/program';
import ProgramCreate from '../../pages/program/create/create-edit';
import Property from '../../pages/settings/property/property';
import PropertyCreate from '../../pages/settings/property/create/create-edit';
import PropertyUnit from '../../pages/settings/property/units/property-unit';
import PropertyUnitCreate from '../../pages/settings/property/units/create/create-edit';
import PropertyReports from '../../pages/settings/property/reports-settings/reports-settings';
import Ranking from '../../pages/data-center/ranking/ranking';
import Records from '../../pages/records/records';
import Registers from '../../pages/data-center/evolution/registers/registers';
import Requirements from '../../pages/settings/data/font/requirements/requirements';
import RequirementsCreate from '../../pages/settings/data/font/requirements/create/create-edit';
import Settings from '../../pages/settings/settings';
import Supply from '../../pages/settings/supply/supply';
import SupplyCreate from '../../pages/settings/supply/create/create-edit';
import SupplyRequirement from '../../pages/settings/supply/supply-requirement/supply-requirement';
import SupplyRequirementCreate from '../../pages/settings/supply/supply-requirement/create/create-edit';
import SupplyRequirementProperties from '../../pages/settings/supply-requirement/supply-requirement';
// import SupplyRequirementPropertiesCreate from '../../pages/settings/supply-requirement/create/create-edit';
import Font from '../../pages/settings/data/font/font';
import FontCreate from '../../pages/settings/data/font/create/create-edit';
import Truck from '../../pages/settings/gestor/truck/truck';
import TruckCreate from '../../pages/settings/gestor/truck/create/create-edit';
import UserRole from '../../pages/settings/role/role';
import UserRoleCreate from '../../pages/settings/role/create/create-edit';
import User from '../../pages/settings/user/user';
import UserCreate from '../../pages/settings/user/create/create-edit';
import SupplyKind from '../../pages/settings/supply-kind/supply-kind';
import SupplyKindCreate from '../../pages/settings/supply-kind/create/create-edit';

export const PagesList = [
    {
        name: 'Materials',
        code: 'MaterPage',
        Component: <Material />,
        CreateEdit: <MaterialCreate />
    },
    {
        name: 'Material Kind',
        code: 'MatkiPage',
        Component: <MaterialKind />,
        CreateEdit: <MaterialKindCreate />
    },
    {
        name: 'Properties',
        code: 'PropePage',
        Component: <Property />,
        CreateEdit: <PropertyCreate />,
        Config: <PropertyReports />,
        Units: <PropertyUnit />,
        UnitsCreateEdit: <PropertyUnitCreate />
    },
    {
        name: 'Company',
        code: 'CompaPage',
        Component: <Company />,
        CreateEdit: <CompanyCreate />,
        Trucks: <Truck />,
        TrucksCreateEdit: <TruckCreate />,
        DefaultValues: <DefaultValues />
    },
    {
        name: 'Programs',
        code: 'ProgrPage',
        Component: <Program />,
        CreateEdit: <ProgramCreate />
    },
    {
        name: 'Calendar',
        code: 'CalPage',
        Component: <CalendarPage />,
        CreateEdit: null
    },
    {
        name: 'Centro de Datos',
        code: 'DatacPage',
        Component: <DataCenter />,
        CreateEdit: null
    },
    {
        name: 'Panel de Control',
        code: 'CtrlpPage',
        Component: <ControlPanel />,
        CreateEdit: null
    },
    {
        name: 'Cierre Mensual',
        code: 'MonthPage',
        Component: <MontlyClosure />,
        CreateEdit: null
    },
    {
        name: 'Camiones',
        code: 'TruckPage',
        Component: <Truck />,
        CreateEdit: <TruckCreate />
    },
    {
        name: 'Cambio de roles',
        code: 'RolecPage',
        Component: <ProfileSelection />,
        CreateEdit: null
    },
    {
        name: 'Invitaciones',
        code: 'InvitPage',
        Component: <Invitations />,
        CreateEdit: <InvitationsCreate />
    },
    {
        name: 'Reportes Bzero',
        code: 'BzrpsPage',
        Component: <BZeroReports />,
        CreateEdit: null
    },
    {
        name: 'Mi perfil',
        code: 'ProfiPage',
        Component: <MyProfile />,
        CreateEdit: null
    },
    {
        name: 'Editar perfil',
        code: 'EdipfPage',
        Component: <EditProfile />,
        CreateEdit: null
    },
    {
        name: 'Cambiar contraseña',
        code: 'ChgpsPage',
        Component: <ChangePass />,
        CreateEdit: null
    },
    {
        name: 'Roles',
        code: 'RolesPage',
        Component: <UserRole />,
        CreateEdit: <UserRoleCreate />
    },
    {
        name: 'Secciones',
        code: 'SectiPage',
        Component: <Appsections />,
        CreateEdit: <AppsectionsCreate />
    },
    {
        name: 'Sinader',
        code: 'SinadPage',
        Component: <AutocompleteArray />
    },
    {
        name: 'Valores por defecto',
        code: 'DefauPage',
        Component: <DefaultValues />
    },
    {
        name: 'Settings',
        code: 'SettiPage',
        Component: <Settings />,
        CreateEdit: null
    },
    {
        name: 'Evolution',
        code: 'EvolPage',
        Component: <Evolution />,
        CreateEdit: null
    },
    {
        name: 'Registers',
        code: 'RegsPage',
        Component: <Registers />,
        CreateEdit: null
    },
    {
        name: 'Control de basura',
        code: 'GCPage',
        Component: <GarbageControl />,
        CreateEdit: null
    },
    {
        name: 'Ranking',
        code: 'RankPage',
        Component: <Ranking />,
        CreateEdit: null
    },
    {
        name: 'Users',
        code: 'UsersPage',
        Component: <User />,
        CreateEdit: <UserCreate />
    },
    {
        name: 'Clients',
        code: 'ClientsPage',
        Component: <Client />,
        CreateEdit: <ClientCreate />,
        Properties: <ClientProperties />
    },
    {
        name: 'Data',
        code: 'DataPage',
        Component: <Data />,
        CreateEdit: <DataCreate />
    },
    {
        name: 'Font',
        code: 'FontPage',
        Component: <Font />,
        CreateEdit: <FontCreate />,
        Config: <Requirements />,
        ConfigCreateEdit: <RequirementsCreate />
    },
    {
        name: 'Records',
        code: 'RecordsPage',
        Component: <Records />,
        CreateEdit: null
    },
    {
        name: 'Help',
        code: 'HelpPage',
        Component: <Help />,
        CreateEdit: null
    },
    {
        name: 'Qr',
        code: 'QrPage',
        Component: <QR />,
        CreateEdit: <CreateQR />
    },
    {
        name: 'Supply',
        code: 'SupplyPage',
        Component: <Supply />,
        CreateEdit: <SupplyCreate />,
        Config: <SupplyRequirement />,
        ConfigCreateEdit: <SupplyRequirementCreate />
    },
    {
        name: 'Supply Kind',
        code: 'SupplyKindPage',
        Component: <SupplyKind />,
        CreateEdit: <SupplyKindCreate />
        // Config: <SupplyRequirement />,
        // ConfigCreateEdit: <SupplyRequirementCreate />
    },
    {
        name: 'Supply Requirements',
        code: 'SupplyReqPage',
        Component: <SupplyRequirementProperties />,
        CreateEdit: <SupplyRequirementProperties />
        // NotVisible: <NotVisible />,
        // NotVisibleEdit: <NotVisibleCreateEdit />
    }
];
