import { FormBuilderFields } from '../../../../components/formBuilder/form-builder.interface';

export const FormCreateSupplyRequirement: FormBuilderFields[] = [
    {
        label: 'Nombre',
        fieldNameId: 'name',
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese el nombre',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 200,
                state: true,
                message: 'Máximo 200 caracteres'
            }
        }
    },
    {
        label: 'Insumo',
        fieldNameId: 'supply',
        fieldType: 'select',
        elements: [],
        fieldPlaceholder: 'Seleccione el insumo',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Fase de entrega',
        fieldNameId: 'deliveryPhase',
        fieldType: 'select',
        elements: [
            { id: 'A', name: 'A' },
            { id: 'B', name: 'B' }
        ],
        fieldPlaceholder: 'Seleccione la fase de entrega',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Area',
        fieldNameId: 'area',
        fieldType: 'select',
        elements: [
            { id: 'garbage', name: 'Basura' },
            { id: 'reusable', name: 'Reutilizable' }
        ],
        fieldPlaceholder: 'Seleccione el area',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Control de stock',
        fieldNameId: 'stockControl',
        fieldType: 'select',
        elements: [
            {
                id: 'control-stock-yes',
                name: 'Si'
            },
            {
                id: 'control-stock-no',
                name: 'No'
            }
        ],
        fieldPlaceholder: 'Ingrese el control de stock',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Stock mínimo',
        fieldNameId: 'minStock',
        fieldType: 'number',
        dependsOnOne: [
            {
                fieldNameId: 'stockControl',
                value: 'Si'
            }
        ],
        fieldPlaceholder: 'Ingrese el stock mínimo',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Precio',
        fieldNameId: 'price',
        fieldType: 'number',
        fieldPlaceholder: 'Ingrese el precio',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Tipología',
        fieldNameId: 'tipology',
        fieldType: 'select',
        elements: [
            { id: 'direct', name: 'Directo' },
            { id: 'indirect', name: 'Indirecto' }
        ],
        fieldPlaceholder: 'Seleccione la tipología',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    },
    {
        label: 'Nombre indirecto',
        fieldNameId: 'nameIndirect',
        dependsOnOne: [
            {
                fieldNameId: 'tipology',
                value: 'Indirecto'
            }
        ],
        fieldType: 'text',
        fieldPlaceholder: 'Ingrese el nombre indirecto',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: false,
                message: 'Este campo es requerido'
            },
            min: {
                length: 3,
                state: true,
                message: 'Mínimo 3 carateres'
            },
            max: {
                length: 200,
                state: true,
                message: 'Máximo 200 caracteres'
            }
        }
    },
    {
        label: 'Inmueble',
        fieldNameId: 'property',
        fieldType: 'select',
        elements: [],
        fieldPlaceholder: 'Seleccione el inmueble',
        isFocusInClean: true,
        validations: {
            type: 'string',
            required: {
                state: true,
                message: 'Este campo es requerido'
            }
        }
    }
    // {
    //     label: 'Unidades',
    //     fieldNameId: 'TABLE',
    //     fieldType: 'table',
    //     elements: [
    //         {
    //             fieldNameId: 'col1',
    //             fieldType: 'select',
    //             elements: [
    //                 {id: 'tt1', name: 't2'}
    //             ],
    //             validations: {
    //                 type: 'string',
    //                 required: {
    //                     state: false,
    //                     message: ''
    //                 }
    //             }
    //         },
    //         {
    //             fieldNameId: 'col2',
    //             fieldType: 'number',
    //             validations: {
    //                 type: 'string',
    //                 required: {
    //                     state: false,
    //                     message: ''
    //                 }
    //             }
    //         }
    //     ],
    //     validations: {
    //         type: 'string',
    //         required: {
    //             state: false,
    //             message: ''
    //         }
    //     }
    // }
    // UNIDADES is made dynamically
    // {
    //     label: 'Unidades',
    //     fieldNameId: 'propertyUnitsInvisible-{idPropertyUnit}',
    //     fieldType: 'select multiple' or 'select', depending on direct/indirect
    //     elements: [propertyUnits in property],
    //     depends: 'property',
    //     dependsValues: [specific property],
    //     fieldPlaceholder: 'Seleccione la unidad' or 'seleccione las unidades',
    //     isFocusInClean: true,
    //     validations: {
    //         type: 'string',
    //         required: {
    //             state: false,
    //             message: 'Este campo es requerido'
    //         }
    //     }
    // },
];
