import '../garbage-control.scss';
import { useEffect, useState } from 'react';
/* import { SupplyInterface } from '../../../../interface'; */

/* interface DataItemInterface {
    id: string;
    supply: SupplyInterface;
    capacity: number;
    propertyUnit: string;
    propertyUnitName: string;
    date: string;
    total: number;
} */

interface Props {
    data: any[];
}

const GarbageDeliveriesTable = ({ data }: Props) => {
    const [tableData, setTableData] = useState<any[]>([]);

    const FormatTableData = () => {
        const groupedData = Object.values(
            data.reduce((acc, item: any) => {
                const key = item.property_unit;

                // Inicializa el objeto para property_unit si no existe
                if (!acc[key]) {
                    acc[key] = {
                        id: item.property_unit,
                        propertyUnit: item.property_unit_name,
                        data: [],
                        consumption: 0 // Inicializa el consumo en 0
                    };
                }

                // Busca el índice del objeto en `data` que tiene la misma `capacity`
                const capacityIndex = acc[key].data.findIndex(
                    (supply: any) =>
                        supply.capacity === parseFloat(item.capacity)
                );

                // Si no se encuentra un objeto con esa capacidad, agregarlo
                if (capacityIndex === -1) {
                    acc[key].data.push({
                        capacity: parseFloat(item.capacity),
                        total: parseFloat(item.quantity) // Inicia el total con la cantidad actual
                    });
                } else {
                    // Si ya existe un objeto con esa capacidad, sumar la cantidad
                    acc[key].data[capacityIndex].total =
                        parseFloat(acc[key].data[capacityIndex].total) +
                        parseFloat(item.quantity);
                }

                return acc;
            }, {})
        );

        // Calcular el consumo total para cada property_unit
        groupedData.forEach((item: any) => {
            item.consumption = item.data.reduce(
                (sum: number, dataItem: any) =>
                    sum + dataItem.total * dataItem.capacity,
                0
            );
        });
        // Suma total de todos los consumos
        const totalConsumption = groupedData.reduce(
            (sum: number, item: any) => sum + parseFloat(item.consumption),
            0
        );

        // Añadir porcentaje a cada objeto
        groupedData.forEach((item: any) => {
            item.percentage = (
                (item.consumption / totalConsumption) *
                100
            ).toFixed(1);
        });
        groupedData.sort((a: any, b: any) =>
            a.propertyUnit.localeCompare(b.propertyUnit)
        );
        setTableData(groupedData);
    };

    useEffect(() => {
        FormatTableData();
    }, [data]);

    return (
        <table className='garbagecontrol__costs__content__tableContainer__div__table subtitle'>
            <thead className='garbagecontrol__costs__content__tableContainer__div__table__head'>
                <tr className='garbagecontrol__costs__content__tableContainer__div__table__head__row'>
                    <th className='garbagecontrol__costs__content__tableContainer__div__table__head__row__item'>
                        Unidad
                    </th>
                    <th
                        className='garbagecontrol__costs__content__tableContainer__div__table__head__row__item bags-column'
                        colSpan={4}
                    >
                        Bolsas entregadas
                    </th>
                    <th className='garbagecontrol__costs__content__tableContainer__div__table__head__row__item'>
                        Consumo total (L)
                    </th>
                    <th className='garbagecontrol__costs__content__tableContainer__div__table__head__row__item'>
                        % de participación
                    </th>
                </tr>
                <tr className='garbagecontrol__costs__content__tableContainer__div__table__head__row'>
                    <th className='garbagecontrol__costs__content__tableContainer__div__table__head__row__item'></th>
                    <th className='garbagecontrol__costs__content__tableContainer__div__table__head__row__item bag-item'>
                        30 L
                    </th>
                    <th className='garbagecontrol__costs__content__tableContainer__div__table__head__row__item bag-item'>
                        80 L
                    </th>
                    <th className='garbagecontrol__costs__content__tableContainer__div__table__head__row__item bag-item'>
                        140 L
                    </th>
                    <th className='garbagecontrol__costs__content__tableContainer__div__table__head__row__item bag-item'>
                        220 L
                    </th>
                    <th className='garbagecontrol__costs__content__tableContainer__div__table__head__row__item'></th>
                    <th className='garbagecontrol__costs__content__tableContainer__div__table__head__row__item'></th>
                </tr>
            </thead>
            <tbody className='garbagecontrol__costs__content__tableContainer__div__table__body'>
                {tableData.map((item, index) => (
                    <tr
                        className='garbagecontrol__costs__content__tableContainer__div__table__body__row'
                        key={item.id}
                    >
                        <td className='garbagecontrol__costs__content__tableContainer__div__table__body__row__item'>
                            {item.propertyUnit}
                        </td>
                        {[30, 80, 140, 220].map(capacity => (
                            <td
                                key={capacity}
                                className='garbagecontrol__costs__content__tableContainer__div__table__body__row__item'
                            >
                                {item.data.reduce(
                                    (total, element) =>
                                        parseInt(element.capacity) === capacity
                                            ? element.total.toLocaleString(
                                                  'es-CL',
                                                  {
                                                      minimumFractionDigits: 0,
                                                      maximumFractionDigits: 1
                                                  }
                                              )
                                            : total,
                                    0
                                )}
                            </td>
                        ))}
                        <td className='garbagecontrol__costs__content__tableContainer__div__table__body__row__item'>
                            {parseFloat(item.consumption).toLocaleString(
                                'es-CL',
                                {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 1
                                }
                            )}
                        </td>
                        <td className='garbagecontrol__costs__content__tableContainer__div__table__body__row__item'>
                            {item.percentage}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default GarbageDeliveriesTable;
