import { useEffect, useState } from 'react';
import { FormBuilderFields, Config } from './form-builder.interface';
import {
    DeleteOutlined,
    EditOutlined,
    PlusOutlined,
    SaveOutlined
} from '@ant-design/icons';
import './formTableItem.scss';

interface Item {
    editable: boolean; // editable in table
    dataValue: string | number | null; // value to send to back
    showValue: string | number | null; // value to show in front
}

interface Row {
    [fieldNameId: string]: Item;
}

export default function FormTableItem({
    item,
    config,
    initialValues,
    handleChange
}: {
    item: FormBuilderFields;
    config?: Config;
    initialValues: any;
    handleChange: (value) => {};
}) {
    const [tableInputs, setTableInputs] = useState<Row>({});
    const [tableItems, setTableItems] = useState<Row[]>([]);

    useEffect(() => {
        if (!initialValues) {
            return;
        }

        // TODO any type
        const newInitialValues: any[] = [];

        initialValues.forEach(element => {
            const newRow: any = {};
            Object.entries(element).forEach(([k, v]) => {
                const newItem: any = {
                    editable: false,
                    dataValue: v,
                    showValue: v
                };

                newRow[k] = newItem;
            });
            newInitialValues.push(newRow);
        });

        setTableItems(newInitialValues);
        setTableInputs({});
    }, [initialValues]);

    const addItem = () => {
        // TODO check required
        // if (formValues.property === '') {
        //     return;
        // }
        const newTableInputs = structuredClone(tableInputs);

        // make new row non-editable
        item.elements?.forEach(elem => {
            if (newTableInputs[elem.fieldNameId]) {
                newTableInputs[elem.fieldNameId].editable = false;
            } else {
                newTableInputs[elem.fieldNameId] = {
                    editable: false,
                    showValue: null,
                    dataValue: null
                };
            }
        });

        const newTableItems = structuredClone(tableItems);
        newTableItems.push(newTableInputs);
        setTableItems(newTableItems);

        handleChange({
            target: {
                name: item.fieldNameId,
                value: newTableItems.map(obj => {
                    const transformedObj: Record<string, any> = {};
                    Object.keys(obj).forEach(key => {
                        transformedObj[key] = obj[key].dataValue;
                    });
                    return transformedObj;
                })
            }
        });
        // TODO set as default
        // setFormValues({
        //     property: '',
        //     proratio: 0.01
        // });
    };

    const handleInputChange = (e, fieldNameId) => {
        const changedItem = item.elements?.find(
            elem => elem.fieldNameId === fieldNameId
        );

        const newTableInputs = { ...tableInputs };

        if (changedItem.fieldType === 'select') {
            // TODO if select, obtain the showValue
            const showValue = changedItem.elements.find(elem => {
                return elem.id === e.target.value;
            });
            newTableInputs[fieldNameId] = {
                editable: false,
                showValue: showValue.name,
                dataValue: e.target.value
            };
        } else if (changedItem.fieldType === 'number') {
            newTableInputs[fieldNameId] = {
                editable: false,
                showValue: e.target.value,
                dataValue: e.target.value
            };
        }

        setTableInputs(newTableInputs);
    };

    const changeRowEditable = index => {
        const newTableItems = structuredClone(tableItems);

        // make editable all values
        Object.keys(newTableItems[index]).forEach(key => {
            newTableItems[index][key].editable =
                !newTableItems[index][key].editable;
        });

        setTableItems(newTableItems);
    };

    const changeRowValues = (e, index, fieldNameId) => {
        // // check if it is a select
        // newTableItems[index][fieldNameId].dataValue = e.target.value;
        // newTableItems[index][fieldNameId].showValue = e.target.value;

        const changedItem = item.elements?.find(
            elem => elem.fieldNameId === fieldNameId
        );

        const newTableItems = structuredClone(tableItems);

        if (changedItem.fieldType === 'select') {
            // TODO if select, obtain the showValue
            const showValue = changedItem.elements.find(elem => {
                return elem.id === e.target.value;
            });
            newTableItems[index][fieldNameId].dataValue = e.target.value;
            newTableItems[index][fieldNameId].showValue = showValue;
        } else if (changedItem.fieldType === 'number') {
            newTableItems[index][fieldNameId].dataValue = e.target.value;
            newTableItems[index][fieldNameId].showValue = e.target.value;
        }

        handleChange({
            target: {
                name: item.fieldNameId,
                value: newTableItems.map(obj => {
                    const transformedObj: Record<string, any> = {};
                    Object.keys(obj).forEach(key => {
                        transformedObj[key] = obj[key].dataValue;
                    });
                    return transformedObj;
                })
            }
        });

        setTableItems(newTableItems);
    };

    const handleDeleteRow = index => {
        const newTableItems = structuredClone(tableItems);

        newTableItems.splice(index, 1);

        handleChange({
            target: {
                name: item.fieldNameId,
                value: newTableItems.map(obj => {
                    const transformedObj: Record<string, any> = {};
                    Object.keys(obj).forEach(key => {
                        transformedObj[key] = obj[key].dataValue;
                    });
                    return transformedObj;
                })
            }
        });

        setTableItems(newTableItems);
    };

    const renderControl = item => {
        switch (item.fieldType) {
            case 'text':
                return (
                    <input
                        type='text'
                        value={
                            tableInputs[item.fieldNameId]
                                ? tableInputs[item.fieldNameId].showValue ?? ''
                                : ''
                        }
                        onChange={e => handleInputChange(e, item.fieldNameId)}
                    />
                );
            case 'number':
                return (
                    <input
                        className='formbuilder-table-container--selected-elements--number'
                        type='number'
                        value={
                            tableInputs[item.fieldNameId]
                                ? tableInputs[item.fieldNameId].showValue ?? 0
                                : 0
                        }
                        onChange={e => handleInputChange(e, item.fieldNameId)}
                    />
                );
            case 'select': {
                // can only select it once
                // for example only one row can be for a specific property
                const allChosenOptions = tableItems.map(elem => {
                    return elem[item.fieldNameId].dataValue;
                });
                const allOptions = item.elements.filter(elem => {
                    return !allChosenOptions.includes(elem.id);
                });

                return (
                    <select
                        onChange={e => handleInputChange(e, item.fieldNameId)}
                        value={
                            tableInputs[item.fieldNameId]
                                ? tableInputs[item.fieldNameId].dataValue ?? ''
                                : ''
                        }
                        className='formbuilder-table-container--selected-elements--select'
                    >
                        <option></option>
                        {allOptions.map(elem => {
                            return (
                                <option
                                    key={`table_select_${item.fieldNameId}_${elem.id}`}
                                    value={elem.id}
                                >
                                    {elem.name}
                                </option>
                            );
                        })}
                    </select>
                );
            }
        }
        return <div></div>;
    };

    const renderRowControl = (item, itemValues, index) => {
        const disabled = !config?.editButton ? !itemValues.editable : true;

        switch (item.fieldType) {
            case 'text':
                return (
                    <input
                        type='text'
                        onChange={e =>
                            changeRowValues(e, index, item.fieldNameId)
                        }
                        disabled={disabled}
                        value={itemValues ? itemValues.showValue ?? '' : ''}
                    />
                );
            case 'number':
                return (
                    <input
                        type='number'
                        onChange={e =>
                            changeRowValues(e, index, item.fieldNameId)
                        }
                        disabled={disabled}
                        value={itemValues ? itemValues.showValue ?? '' : ''}
                        className='formbuilder-table-container--selected-elements--number'
                    />
                );
            case 'select': {
                // can only select it once
                // for example only one row can be for a specific property
                const allChosenOptions = tableItems.map(elem => {
                    return elem[item.fieldNameId].dataValue;
                });
                const allOptions = item.elements.filter(elem => {
                    return (
                        !allChosenOptions.includes(elem.id) ||
                        elem.id === itemValues.dataValue
                    );
                });

                return (
                    <div>
                        <select
                            onChange={e =>
                                handleInputChange(e, item.fieldNameId)
                            }
                            value={itemValues ? itemValues.dataValue ?? '' : ''}
                            disabled={disabled}
                            className='formbuilder-table-container--selected-elements--select'
                        >
                            <option></option>
                            {allOptions.map(elem => {
                                return (
                                    <option
                                        key={`table_select_${item.fieldNameId}_${elem.id}`}
                                        value={elem.id}
                                        disabled={disabled}
                                    >
                                        {elem.name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                );
            }
        }

        return <></>;
    };

    return (
        <div className='formbuilder-table-container'>
            {/* ELEMENTS */}
            <>
                {tableItems.map((tableRow, index) => {
                    // values have editable prop, so check if at least one is editable
                    const editable = Object.values(tableRow).some(
                        elem => elem.editable
                    );
                    return (
                        <div
                            className={
                                'formbuilder-table-container--row' +
                                (index + 1 === tableItems.length &&
                                (item.disabled ?? config?.editButton)
                                    ? ' formbuilder-table-container--last-row'
                                    : '')
                            }
                            key={`table_${index}`}
                        >
                            <>
                                {item.elements?.map((elem, i) => {
                                    return (
                                        <div
                                            className='formbuilder-table-container--row--element'
                                            key={`table_${item.fieldNameId}_${index}_${i}`}
                                        >
                                            {renderRowControl(
                                                elem,
                                                tableRow[elem.fieldNameId],
                                                index
                                            )}
                                        </div>
                                    );
                                })}

                                <div className='formbuilder-table-container--row--element'>
                                    {!config?.editButton ? (
                                        !editable ? (
                                            <>
                                                <button
                                                    type='button'
                                                    onClick={() =>
                                                        changeRowEditable(index)
                                                    }
                                                >
                                                    <EditOutlined />
                                                </button>
                                                <button
                                                    type='button'
                                                    onClick={() =>
                                                        handleDeleteRow(index)
                                                    }
                                                >
                                                    <DeleteOutlined />
                                                </button>
                                            </>
                                        ) : (
                                            <>
                                                <button
                                                    type='button'
                                                    onClick={() =>
                                                        changeRowEditable(index)
                                                    }
                                                >
                                                    <SaveOutlined />
                                                </button>
                                                <button
                                                    type='button'
                                                    onClick={() => {
                                                        setTableItems(
                                                            prevTableItems => {
                                                                const updatedItems =
                                                                    [
                                                                        ...prevTableItems
                                                                    ];

                                                                updatedItems.splice(
                                                                    index,
                                                                    1
                                                                );
                                                                return updatedItems;
                                                            }
                                                        );
                                                    }}
                                                >
                                                    <DeleteOutlined />
                                                </button>
                                            </>
                                        )
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </>
                        </div>
                    );
                })}
            </>

            {/* INPUT */}
            {!(item.disabled ?? config?.editButton) && (
                <div className='formbuilder-table-container--row formbuilder-table-container--last-row'>
                    {item.elements?.map((elem, i) => (
                        <div
                            className='formbuilder-table-container--row--element'
                            key={`tableinput_${item.fieldNameId}_${i}`}
                        >
                            {renderControl(elem)}
                        </div>
                    ))}

                    <div className='formbuilder-table-container--row--element'>
                        <button type='button' onClick={addItem}>
                            <PlusOutlined />
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}
