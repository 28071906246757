export const ApiSupply = `${process.env.REACT_APP_PUBLIC_SERVICE_URL}/supply`;
export const ApiSupplyId = (id: string) =>
    `${process.env.REACT_APP_PUBLIC_SERVICE_URL}/supply/${id}`;
export const ApiSupplyRequirements = (id: string) =>
    `${process.env.REACT_APP_PUBLIC_SERVICE_URL}/supply/${id}/requirements`;
export const ApiSupplyKind = `${process.env.REACT_APP_PUBLIC_SERVICE_URL}/supply-kind`;
export const ApiSupplyKindId = (id: string) =>
    `${process.env.REACT_APP_PUBLIC_SERVICE_URL}/supply-kind/${id}`;
export const ApiSupplyVisibleRequirements = `${process.env.REACT_APP_PUBLIC_SERVICE_URL}/supply-requirement/visible`;
export const ApiSupplyNotVisibleRequirements = (id: string) =>
    `${process.env.REACT_APP_PUBLIC_SERVICE_URL}/supply-requirement/invisible/${id}`;
