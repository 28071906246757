import { PropertyUnitInterface } from './property-unit.interface';
import { PropertyInterface } from './property.interface';
import { SupplyRequirementAppendixInterface } from './supply-requirement-appendix.interface';
// import { SupplyStockInterface } from './supply-stock.interface';
import { SupplyInterface } from './supply.interface';

export interface SupplyRequirementInterface {
    id: string;
    name: string;
    deliveryPhase: string;
    area: string;
    tipology: string;
    minStock: number;
    price: number;
    nameIndirect?: string;
    stockControl: boolean;
    supply: SupplyInterface;
    property: PropertyInterface;
    propertyUnit: PropertyUnitInterface;
    supplyRequirementAppendixIndirect: SupplyRequirementAppendixInterface[];
    state: number;
    createdAt: string;
    updatedAt: string;
}

export interface SupplyRequirementFormCreateInterface {
    name: string;
    deliveryPhase: string;
    area: string;
    tipology: string;
    minStock: number;
    price: number;
    nameIndirect?: string;
    stockControl: any;
    property: string;
    propertyUnit: string | null;
    supply: string;
    propertyUnitsInvisible: any[];
}

export const SupplyRequirementFormValues: SupplyRequirementFormCreateInterface =
    {
        name: '',
        deliveryPhase: '',
        area: '',
        tipology: '',
        minStock: 0,
        price: 0,
        nameIndirect: '',
        stockControl: 0,
        property: '',
        propertyUnit: null,
        supply: '',
        propertyUnitsInvisible: []
    };
