import { HTTP_METHODS } from '../hooks/useCallApi/constants';
import api from '../hooks/useCallApi/request';
import {
    ApiPropertyByUserRole,
    ApiPropertyDataByPropertyGestor,
    ApiUserDataByUser
} from '../service';
import { GetAuthCookie } from './Auth';
import { GetStorage, SaveStorage } from './Storage';

function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
}

const filterPropertyDataForLocalStorage = propertyData => {
    const propertyfiltered = propertyData.map(property => {
        const propertyAllowedKeys = ['id', 'name', 'propertyUnits'];
        property = filterObjectByAttributes(property, propertyAllowedKeys);

        const unitsAllowedKeys = ['id', 'name', 'floor', 'property', 'state'];
        property.propertyUnits = property.propertyUnits.map(unit => {
            return filterObjectByAttributes(unit, unitsAllowedKeys);
        });

        return property;
    });

    return propertyfiltered;
};

const filterObjectByAttributes = (object, attributes) => {
    return Object.keys(object)
        .filter(key => attributes.includes(key))
        .reduce((obj, key) => {
            obj[key] = object[key];
            return obj;
        }, {});
};

const updateStorageProperties = (updateKeys, token, userData, lang) => {
    updateKeys.map(async key => {
        const value = localStorage.getItem(key);
        const propertyId = key.replace('property-', '');
        if (value) {
            try {
                let gestor: string | null = null;
                for (const role of userData.roles) {
                    if (role.gestorData) {
                        gestor = role.gestorData.id;
                        break;
                    }
                }

                const sendData = {
                    method: HTTP_METHODS.POST,
                    path: ApiPropertyDataByPropertyGestor,
                    token,
                    lang,
                    data: { property: propertyId, gestor }
                };
                const response = await api(sendData);

                if (response.status >= 200 && response.status < 300) {
                    const propertyData = Object.fromEntries(
                        Object.entries(response.data).filter(
                            ([key, value]) =>
                                key === 'data' || key === 'id' || key === 'kind'
                        )
                    );
                    localStorage.setItem(key, JSON.stringify(propertyData));
                } else {
                    localStorage.removeItem(key);
                    console.error(
                        `Failed to update ${key}. Status: ${response.status}, StatusText: ${response.statusText}`
                    );
                }
            } catch (error) {
                localStorage.removeItem(key);
                console.error(`Error updating ${key}:`, error);
            }
        }
    });
};

export const fetchAndStoreUserData = async (userData, dispatch) => {
    try {
        const { token } = await GetAuthCookie();
        const lang = await GetStorage(process.env.REACT_APP_PUBLIC_STORE_LANG);

        // update properties/clients
        const rolesIds = userData.roles.map(item => {
            return item.id;
        });
        const uniqueRoles = rolesIds.filter(onlyUnique);
        const sendData = {
            method: HTTP_METHODS.GET,
            path: ApiPropertyByUserRole(userData.id),
            token,
            lang,
            data: {},
            dataHeaders: uniqueRoles
        };
        const response2 = await api(sendData);
        const clientPropertyData = response2?.data?.data?.flatMap(
            client => client.properties
        );

        const allPropertyData = clientPropertyData.map(property => ({
            ...property,
            propertyUnits: property?.units || property?.propertyUnits || []
        }));
        const units = response2.data.data.flatMap(client =>
            client.units ? client.units : []
        );

        const filteredAllPropertyData =
            filterPropertyDataForLocalStorage(allPropertyData);

        SaveStorage(
            filteredAllPropertyData,
            process.env.REACT_APP_PUBLIC_USER_PROPERTIES
        );
        SaveStorage(units, process.env.REACT_APP_PUBLIC_USER_UNITS);
        SaveStorage(
            response2.data.data,
            process.env.REACT_APP_PUBLIC_USER_CLIENTS
        );

        // update cards
        const actualData: { [key: string]: any } = {};

        const cardsData = (
            await api({
                method: HTTP_METHODS.GET,
                path: ApiUserDataByUser(userData.id),
                token,
                lang
            })
        ).data;

        const evolutionCardHistoric = cardsData.data.find(
            item => item.kind === 'evolution-card-historic'
        );
        if (evolutionCardHistoric?.data) {
            SaveStorage(evolutionCardHistoric, 'evolution-card-historic');
            const parsedData = JSON.parse(evolutionCardHistoric.data);
            const last12Labels = parsedData.labels.slice(-12);
            const last12Datasets = parsedData.datasets.map(dataset => ({
                ...dataset,
                data: dataset.data.slice(-12) // Tomamos los últimos 12 valores
            }));
            const filteredData = {
                ...parsedData,
                labels: last12Labels, // Actualizamos los labels
                datasets: last12Datasets // Actualizamos los datasets con los últimos 12 valores
            };
            // setDataApiledChart(filteredData);
            actualData.dataApiledChart = filteredData;

            const materials = parsedData.datasets.map((item: any) => ({
                name: item.id,
                selected: true,
                show: true
            }));
            SaveStorage(materials, process.env.REACT_APP_PUBLIC_USER_MATERIALS);
        }
        const garbagecontrolCard = cardsData.data.find(
            item => item.kind === 'garbagecontrol-card'
        );
        if (garbagecontrolCard?.data) {
            SaveStorage(garbagecontrolCard, 'garbagecontrol-card');
            const parsedData = JSON.parse(garbagecontrolCard.data);
            const last12Labels = parsedData.labels.slice(-12);
            const last12Datasets = parsedData.datasets.map(dataset => ({
                ...dataset,
                data: dataset.data.slice(-12) // Tomamos los últimos 12 valores
            }));
            const filteredData = {
                ...parsedData,
                labels: last12Labels, // Actualizamos los labels
                datasets: last12Datasets // Actualizamos los datasets con los últimos 12 valores
            };
            actualData.garbageData = filteredData;
        }
        const rankingCard = cardsData.data.find(
            item => item.kind === 'ranking-card'
        );
        if (rankingCard?.data) {
            SaveStorage(rankingCard, 'ranking-card');
            const rankingData = JSON.parse(rankingCard.data);
            actualData.ranking = rankingData.ranking ? rankingData.ranking : [];
        }

        const ecoequivalencesCard = cardsData.data.find(
            item => item.kind === 'ecoequivalences-card'
        );
        if (ecoequivalencesCard?.data) {
            SaveStorage(ecoequivalencesCard, 'ecoequivalences-card');
            actualData.ecoequivalences = JSON.parse(ecoequivalencesCard.data);
        }

        const bzeroindexCard = cardsData.data.find(
            item => item.kind === 'bzeroindex-card'
        );
        if (bzeroindexCard?.data) {
            SaveStorage(bzeroindexCard, 'bzeroindex-card');
            actualData.bzeroindex = parseFloat(
                bzeroindexCard.data.replace(',', '.')
            );
        }
        // actual-data
        SaveStorage(actualData, 'actual-data');

        // property data
        const propertyKeys: string[] = [];

        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key?.startsWith('property-')) {
                propertyKeys.push(key);
            }
        }

        // Save only 5. Updating requires request and better not
        // store all of the properties
        const updateKeys = propertyKeys.slice(0, 5);
        const removeKeys = propertyKeys.slice(5);

        // update
        updateStorageProperties(updateKeys, token, userData, lang);

        removeKeys.forEach(key => localStorage.removeItem(key));
    } catch (error) {
        console.error('Fetch error:', error);
        throw error;
    }
};
