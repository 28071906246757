// import { MaterialInterface } from './material.interface';

export interface SupplyKindInterface {
    id: string;
    name: string;
    // description: string;
    // color: string;
    // codeLer: string;
    state: number;
    createdAt: string;
    updatedAt: string;
    // materials?: MaterialInterface[];
}

export interface SupplyKindFormCreateInterface {
    name: string;
    // description: string;
    // color: string;
    // codeLer: string;
}

export const SupplyKindFormValues: SupplyKindFormCreateInterface = {
    name: ''
    // description: '',
    // color: '',
    // codeLer: ''
};
