//  <--HOOKS--> //
import { useToast } from '../../../config/hooks/useToast';
import { useAlert } from '../../../config/hooks/useAlert';
//  <--COMPONENTS--> //
import TableComponent from '../../../components/table';
import { SupplyRequirementTable } from '../../../components/table/data/columns_table';
// import { constantsSupplyRequirementTable } from './constants';
//  <--REDUX--> //
import { UserStoreInterface } from '../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../store/hooks';

interface Props {
    items: any[];
}

const SupplyRequirementTableComponent = ({ items }: Props) => {
    const { ToastElement, toastManagerRef } = useToast();
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const { AlertElement, alertManagerRef } = useAlert({ defaultLang });

    return (
        <>
            <ToastElement ref={toastManagerRef} />
            <AlertElement ref={alertManagerRef} />
            <TableComponent
                data={items}
                headers={SupplyRequirementTable}
                showFilters={true}
                // keysFilter={keysFilter}
                showPagination={true}
            />
        </>
    );
};

export default SupplyRequirementTableComponent;
