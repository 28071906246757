import { useState } from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { HTTP_METHODS } from '../../../../config/hooks/useCallApi/constants';
import { useCallApi } from '../../../../config/hooks/useCallApi';
import {
    ApiGarbageProratio,
    ApiGarbageProratioUpdate,
    ApiSurfaceEstimationByGarbageCostCategory
} from '../../../../config/service';
import { GarbageProratioInterface } from '../../../../interface';

interface Props {
    garbageCost: any;
    date: string;
    garbageProratio: GarbageProratioInterface;
    onRefresh: () => void;
}

const GarbageConsumptionEdit = ({
    date,
    garbageCost,
    garbageProratio,
    onRefresh
}: Props) => {
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const [method, setMethod] = useState<number>(0);
    const [consumption, setConsumption] = useState<string>('');

    const handleCancelClick = () => {
        setMethod(0);
        setConsumption('');
    };

    const handleSaveClick = async () => {
        const updatedData = {
            ...garbageProratio,
            consumptionL: consumption
                ? parseFloat(consumption)
                : garbageProratio.consumptionL,
            manualCalc: true
        };

        const response = await GetData(
            ApiGarbageProratio(garbageProratio.id),
            HTTP_METHODS.PATCH,
            updatedData
        );
        if (response.status) {
            const values = {
                garbageCost,
                date: `${date.substring(0, 4)}-${date.substring(4, 6)}`
            };
            const response2 = await GetData(
                ApiGarbageProratioUpdate,
                HTTP_METHODS.POST,
                values
            );

            if (response2.status) {
                onRefresh();
            }
        }
    };

    const GetSurfaceEstimation = async () => {
        setMethod(1);
        const values = {
            garbageCost: garbageCost.id,
            category: garbageProratio.propertyUnit.propertyUnitCategory.id
        };
        const response = await GetData(
            ApiSurfaceEstimationByGarbageCostCategory,
            HTTP_METHODS.POST,
            values
        );
        setConsumption(
            (
                parseFloat(response.data.factor) *
                parseFloat(garbageProratio.propertyUnit.surface)
            ).toString()
        );
    };

    return (
        <div className='garbagecontrol__consumption'>
            <div className='garbagecontrol__consumption__title subtitle'>
                Modificar consumo de basura
            </div>
            <div className='garbagecontrol__consumption__subtitle subtitle'>
                Unidad: {garbageProratio.propertyUnit.name}
            </div>
            <div className='garbagecontrol__consumption__content subtitle'>
                <button
                    className={`garbagecontrol__consumption__content__button ${
                        method === 1 && 'method-active'
                    }`}
                    onClick={() => {
                        GetSurfaceEstimation();
                    }}
                >
                    Estimar consumo por m<sup>2</sup>
                </button>
                {method === 1 && (
                    <div className='garbagecontrol__consumption__content__value subtitle'>
                        <div className='garbagecontrol__consumption__content__value__method1 subtitle'>
                            <div>
                                Superficie [m<sup>2</sup>]:
                            </div>
                            <div>{garbageProratio.propertyUnit.surface}</div>
                            <ArrowRightOutlined style={{ color: '#1f1f1f' }} />
                            <div>Consumo [L]:</div>
                            <div>
                                {parseFloat(consumption).toLocaleString(
                                    'es-CL',
                                    {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 1
                                    }
                                )}
                            </div>
                        </div>
                    </div>
                )}
                <button
                    className={`garbagecontrol__consumption__content__button ${
                        method === 2 && 'method-active'
                    }`}
                    onClick={() => setMethod(2)}
                >
                    Modificar consumo manualmente
                </button>
                {method === 2 && (
                    <div className='garbagecontrol__consumption__content__value subtitle'>
                        <div className='garbagecontrol__consumption__content__value__method2 subtitle'>
                            <label className='garbagecontrol__consumption__content__value__method2__label subtitle'>
                                Ingrese consumo mensual [L]:
                            </label>
                            <input
                                className='garbagecontrol__consumption__content__value__method2__input subtitle'
                                value={consumption}
                                onChange={e => setConsumption(e.target.value)}
                            />
                        </div>
                    </div>
                )}
            </div>
            {method !== 0 && (
                <div className='garbagecontrol__consumption__buttons subtitle'>
                    <button
                        type='button'
                        className='button button-body--cancel-consumption subtitle'
                        onClick={handleCancelClick}
                    >
                        Cancelar
                    </button>
                    <button
                        type='button'
                        className='button button-body--save-consumption subtitle'
                        onClick={handleSaveClick}
                    >
                        Guardar
                    </button>
                </div>
            )}
            {LoadingData && <LoaderElement />}
        </div>
    );
};

export default GarbageConsumptionEdit;
