import { useEffect } from 'react';
import { useNavigate } from 'react-router';

export default function RestoreStorage() {
    const navigate = useNavigate();

    const clearAllStorage = () => {
        localStorage.clear();
        sessionStorage.clear();
        deleteAllCookies();
    };

    const deleteAllCookies = () => {
        document.cookie.split(';').forEach(cookie => {
            const eqPos = cookie.indexOf('=');
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
        });
    };

    useEffect(() => {
        clearAllStorage();
        navigate('/login');
    }, []);

    return <div></div>;
}
