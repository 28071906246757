import '../../settings.scss';
import '../../../../scss/global/global.scss';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
//  <--COMPONENTS--> //
import FormBuilder from '../../../../components/formBuilder/formBuilder';
// import ButtonBody from '../../../../components/button/button';
//  <--HOOKS--> //
import { useToast } from '../../../../config/hooks/useToast';
import { useAlert } from '../../../../config/hooks/useAlert';
import { useCallApi } from '../../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../../config/hooks/useCallApi/constants';
//  <--INTERFACE--> //
import {
    // SupplyInterface,
    SupplyRequirementFormCreateInterface,
    SupplyRequirementFormValues,
    SupplyRequirementInterface
    // SupplyRequirementInterface
} from '../../../../interface';
//  <--REDUX--> //
import { UserStoreInterface } from '../../../../store/slices/users/user.interface';
import { PropertyStoreInterface } from '../../../../store/slices/property/property.interface';
import { useAppSelector } from '../../../../store/hooks';
//  <--SERVICES--> //
import {
    ApiSupply,
    ApiSupplyRequirement,
    // ApiSupplyRequirement,
    ApiSupplyRequirementId
} from '../../../../config/service';
//  <--OTHERS--> //
import { lang } from '../../../langs';
import { FormCreateSupplyRequirement } from './form';
// import ButtonBody from '../../../../components/button/button';
import { FormBuilderFields } from '../../../../components/formBuilder/form-builder.interface';
// import NotVisibleRatioForm from '../not-visible/not-visible-ratio-form';

const SupplyRequirementCreate = ({
    entity,
    onUpdate,
    onDelete
}: {
    entity: SupplyRequirementInterface | null;
    onUpdate: (entity) => void;
    onDelete: (id) => void;
}) => {
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const { ConfigToast, setConfigToast, Colors } = useToast();
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const fieldRef = useRef(null);
    const {
        AlertElement,
        alertManagerRef,
        ConfigAlert,
        setConfigAlert,
        Defaults
    } = useAlert({ defaultLang });
    const {
        [defaultLang as keyof typeof lang]: {
            pageSupplyRequirement: {
                supplyRequirement: {
                    createTitle,
                    editTitle,
                    detailTitle,
                    deleteMessage,
                    deleteTitle
                }
            }
        }
    } = lang;
    const navigate = useNavigate();

    const { propertyData }: PropertyStoreInterface = useAppSelector(
        state => state.property
    );
    const [readyForm, setReadyForm] = useState<boolean>(false);
    const [detailPage, setDetailPage] = useState<boolean>(true);

    // new Stuff
    const [formData, setFormData] =
        useState<SupplyRequirementFormCreateInterface>();

    /**
     * @description functions called at the beginning
     */
    useEffect(() => {
        const getSupplyData = async () => {
            setReadyForm(false);
            const response = await GetData(ApiSupply, HTTP_METHODS.GET);
            const supplyData = [...response.data].sort((a, b) =>
                a.name.localeCompare(b.name)
            );

            const index = FormCreateSupplyRequirement.findIndex(
                elem => elem.fieldNameId === 'supply'
            );
            FormCreateSupplyRequirement[index].elements = supplyData;
            setReadyForm(true);
        };

        // const getPropertyData = async () = {
        //     const propertyData = (
        //         [...(data.property?.propertyUnits || [])].sort((a, b) =>
        //             a.name.localeCompare(b.name)
        //         )
        //     );
        // }

        getSupplyData();
        // getPropertyUnitData();
    }, []);

    useEffect(() => {
        setDetailPage(true);
    }, [entity]);

    /**
     * @description populates form data with entity data
     */
    useEffect(() => {
        /**
         * @description
         * @function SetData is a function that sets data lists to show in form as options
         */
        const populateFormValuesFromEntity = async () => {
            if (!entity) {
                setFormData(undefined);
                return;
            }

            const newFormData: SupplyRequirementFormCreateInterface = {
                ...entity,
                supply: entity.supply.id,
                property: entity.property.id,
                propertyUnit: entity.propertyUnit ? entity.propertyUnit.id : '',
                stockControl: entity.stockControl
                    ? 'control-stock-yes'
                    : 'control-stock-no',
                propertyUnitsInvisible:
                    entity.supplyRequirementAppendixIndirect?.map(item => {
                        return {
                            unidad: item.supplyRequirementInvisible
                                ?.propertyUnit?.id,
                            proratio: item.proratio
                        };
                    })
            };

            setReadyForm(false);
            setFormData(newFormData);
            setReadyForm(true);
        };

        const dynamicFormValues = () => {
            // property values
            const index = FormCreateSupplyRequirement.findIndex(
                elem => elem.fieldNameId === 'property'
            );
            FormCreateSupplyRequirement[index].elements = [
                ...propertyData
            ].sort((a, b) => a.name.localeCompare(b.name));

            // Property Units depends on Property
            // check length so to not add more than once
            if (FormCreateSupplyRequirement.length <= 11) {
                propertyData.forEach(property => {
                    if (property.propertyUnits.length === 0) {
                        // TODO phase B
                        return;
                    }

                    const newItemDirect: FormBuilderFields = {
                        label: 'Unidad',
                        fieldNameId: 'propertyUnit',
                        fieldType: 'select',
                        elements: [...property.propertyUnits].sort((a, b) =>
                            a.name.localeCompare(b.name)
                        ),
                        dependsOnAll: [
                            { fieldNameId: 'property', value: property.name },
                            { fieldNameId: 'deliveryPhase', value: 'A' },
                            { fieldNameId: 'tipology', value: 'Directo' }
                        ],
                        fieldPlaceholder: 'Seleccione las unidades',
                        isFocusInClean: true,
                        validations: {
                            type: 'array' as const,
                            required: {
                                state: false,
                                message: 'Este campo es requerido'
                            }
                        }
                    };
                    const newItemIndirect: FormBuilderFields = {
                        label: 'Unidades',
                        fieldNameId: 'propertyUnitsInvisible',
                        fieldType: 'table',
                        elements: [
                            {
                                fieldNameId: 'unidad',
                                fieldType: 'select',
                                elements: [...property.propertyUnits].sort(
                                    (a, b) => a.name.localeCompare(b.name)
                                )
                            },
                            {
                                fieldNameId: 'proratio',
                                fieldType: 'number'
                            }
                        ],
                        dependsOnAll: [
                            { fieldNameId: 'property', value: property.name },
                            { fieldNameId: 'deliveryPhase', value: 'A' },
                            { fieldNameId: 'tipology', value: 'Indirecto' }
                        ],
                        fieldPlaceholder: 'Seleccione las unidades',
                        isFocusInClean: true,
                        validations: {
                            type: 'array' as const,
                            required: {
                                state: true,
                                message: 'Este campo es requerido'
                            }
                        }
                    };

                    FormCreateSupplyRequirement.push(newItemDirect);
                    FormCreateSupplyRequirement.push(newItemIndirect);
                });
            }
        };

        populateFormValuesFromEntity();
        dynamicFormValues();
    }, [entity]);

    /**
     * @description
     * @function OnSubmit is a function that is called when the form is submitted
     * @param values is a object that contains the data of the form
     */
    const OnSubmit = async (values: SupplyRequirementFormCreateInterface) => {
        const valuesToSend = {
            ...values,
            stockControl: values.stockControl === 'control-stock-yes' ? 1 : 0,
            propertyUnitsInvisible: values.propertyUnitsInvisible.map(item => {
                return {
                    propertyUnitId: item.unidad,
                    proratio: item.proratio
                };
            }),
            propertyUnit: values.propertyUnit ? values.propertyUnit : null
        };

        try {
            const response = entity
                ? await GetData(
                      ApiSupplyRequirementId(entity.id),
                      HTTP_METHODS.PATCH,
                      valuesToSend
                  )
                : await GetData(
                      ApiSupplyRequirement,
                      HTTP_METHODS.POST,
                      valuesToSend
                  );

            const { message, data, status } = response;

            if (status) {
                ConfigToast.text = message;
                ConfigToast.backColor = Colors.Success;
                onUpdate(data);
                setReadyForm(true);
                setDetailPage(true);
                navigate(`/panel/supply-requirements/edit/${data.id}`);
            } else {
                ConfigToast.text = data.sqlMessage;
                ConfigToast.backColor = Colors.Error;
                ConfigToast.title = 'Error';
            }
            setConfigToast(ConfigToast);
        } catch (err) {
            ConfigToast.text = (err as Error).message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
        }
    };

    /**
     * @description
     * @function FocusOnInput is a function that is called when the form's cancel button is clicked
     * @param ref is a reference to the form
     */
    const FocusOnInput = (ref: any) => {
        ref.current.focus();
    };

    /**
     * @description function that is executed when the delete button is pressed
     * @function Delete is a function that launches an alert to confirm the deletion of a COMPANY
     * @param requirementId is a string that contains the id of the COMPANY to be deleted
     */
    const Delete = async () => {
        if (entity === null) {
            return;
        }

        ConfigAlert.title = Defaults.titles.Delete;
        ConfigAlert.message = deleteMessage;
        ConfigAlert.okButtonText = Defaults.buttons.Ok.Accept;
        ConfigAlert.okButtonController = {
            func: ConfirmDelete,
            param: entity.id
        };
        ConfigAlert.cancelButtonText = Defaults.buttons.Cancel.Cancel;
        setConfigAlert(ConfigAlert);
    };

    /**
     * @description function that is executed when the okDelete button is pressed
     * @function Delete is a function that deletes a COMPANY
     * @param requirementId is a string that contains the id of the COMPANY to be deleted
     */
    const ConfirmDelete = async (requirementId: string) => {
        const response = await GetData(
            ApiSupplyRequirementId(requirementId),
            HTTP_METHODS.DELETE
        );

        const { data, status, message } = response;

        if (status) {
            ConfigToast.text = message;
            ConfigToast.backColor = Colors.Success;
            onDelete(requirementId);
        } else {
            ConfigToast.text = data.message;
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
        }
        setConfigToast(ConfigToast);

        navigate('/panel/supply-requirements');
    };

    /**
     * @description
     * @constant FormButtons is a object that contains the buttons of the form
     */
    const FormButtons = {
        ok: {
            controller: OnSubmit
        },
        cancel: {
            controller: FocusOnInput
        }
    };

    return (
        <>
            <AlertElement ref={alertManagerRef} />
            {LoadingData && <LoaderElement />}
            <div className='settings__grid-container__item_right'>
                <div className='settings__grid-container__item_right__title subtitle'>
                    {entity !== null ? (
                        <div>{detailPage ? detailTitle : editTitle}</div>
                    ) : (
                        <div>{createTitle}</div>
                    )}
                </div>

                <div className='settings__grid-container__item_right__content'>
                    <>
                        {readyForm && (
                            <>
                                <FormBuilder
                                    initialValues={
                                        formData?.name
                                            ? formData
                                            : SupplyRequirementFormValues
                                    }
                                    buttons={FormButtons}
                                    lang={defaultLang}
                                    formStructure={FormCreateSupplyRequirement}
                                    focusOn={fieldRef}
                                    config={{
                                        noClearButton: !!entity?.name,
                                        editButton: !!entity?.name && detailPage
                                    }}
                                />
                            </>
                        )}
                    </>
                    {entity !== null && detailPage && (
                        <div className='settings__grid-container__item_right__content__btn'>
                            <button
                                className='settings__grid-container__item_right__content__btn__delete-button item-btn subtitle'
                                onClick={Delete}
                            >
                                {deleteTitle}
                            </button>
                            <button
                                className='settings__grid-container__item_right__content__btn__edit-button item-btn subtitle'
                                onClick={() => setDetailPage(false)}
                            >
                                {editTitle}
                            </button>
                        </div>
                    )}
                </div>

                {entity?.tipology === 'indirect' && (
                    <>
                        <div className='settings__grid-container__item_right__title subtitle'>
                            Requerimientos Asociados
                        </div>
                        {/* <NotVisibleRatioForm
                                    indirectId={requirementId}
                                /> */}
                        {/* <div className='settings__grid-container__item_right__content'>
                                    <ButtonBody
                                        able='true'
                                        kind='button button-body--create'
                                        text='Ver requerimientos'
                                        path={`/panel/supply-requirements/${requirementId}/notvisible`}
                                    ></ButtonBody>
                                </div> */}
                    </>
                )}
            </div>
        </>
    );
};

export default SupplyRequirementCreate;
