import { useRef, useState } from 'react';
import { useCallApi } from '../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../config/hooks/useCallApi/constants';
import CloseIcon from '@material-ui/icons/Close';
import { Cascader, ConfigProvider, DatePicker } from 'antd';
import esES from 'antd/es/locale/es_ES';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { PropertyInterface, RegisterInterface } from '../../interface';
import './download-requirement-files.scss';
import { useToast } from '../../config/hooks/useToast';
import {
    CheckCircleFilled,
    FileExcelFilled,
    PictureFilled
} from '@ant-design/icons';
import { UserStoreInterface } from '../../store/slices/users/user.interface';
import { useAppSelector } from '../../store/hooks';

dayjs.locale('es');

export interface Material {
    id?: string;
    name: string;
    selected: boolean;
}

export default function DownloadRequirementsFiles({
    registers,
    hasDateFilter = false,
    propertyUnits,
    style = 'button'
}: {
    registers?: RegisterInterface[];
    hasDateFilter?: boolean;
    propertyUnits?: PropertyInterface[];
    style?: string;
}) {
    const { GetData, LoaderElement } = useCallApi();
    const { userData }: UserStoreInterface = useAppSelector(
        state => state.users
    );

    const [disableButton, setDisableButton] = useState<boolean>(false);
    const [showPopUp, setShowPopUp] = useState<boolean>(false);
    const [filter, setFilter] = useState<{
        dateIni: string;
        dateFin: string;
        fileType: string;
    }>({
        dateIni: (() => {
            const currentDate = new Date();
            currentDate.setDate(1);
            return currentDate.toISOString().split('T')[0];
        })(),
        dateFin: (() => {
            // last day of current month
            const currentDate = new Date();
            const lastDay = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() + 1,
                0
            );
            return lastDay.toISOString().split('T')[0];
        })(),
        fileType: ''
    });
    const downloadLink = useRef('');
    const {
        ConfigToast,
        setConfigToast,
        ToastElement,
        toastManagerRef,
        Colors
    } = useToast();

    const title = 'Descarga de Documentos';

    const changeDate = date => {
        if (date === null) {
            return;
        }

        const startDate = new Date(date);
        startDate.setDate(1);
        const formattedStartDate = startDate.toISOString().split('T')[0];

        const endDate = new Date(date);
        const lastDay = new Date(
            endDate.getFullYear(),
            endDate.getMonth() + 1,
            0
        );
        const formattedEndDate = lastDay.toISOString().split('T')[0];

        setFilter({
            ...filter,
            dateIni: formattedStartDate,
            dateFin: formattedEndDate
        });
    };

    const handleButton = () => {
        setShowPopUp(true);
    };

    const downloadFiles = async (type: string | null) => {
        setDisableButton(true);
        try {
            await obtainDownloadLink(type);
            if (downloadLink.current) {
                const link = document.createElement('a');
                link.href = downloadLink.current;
                link.download = 'download.zip';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (error) {
            console.log(error);
        }

        setDisableButton(false);
    };

    const obtainDownloadLink = async (type: string | null) => {
        if (propertyUnits && propertyUnits.length === 0) {
            ConfigToast.text = 'Debes seleccionar al menos un inmueble';
            ConfigToast.backColor = Colors.Error;
            ConfigToast.title = 'Error';
            setConfigToast(ConfigToast);
            return;
        }

        const url = `${process.env.REACT_APP_PUBLIC_SERVICE_URL}/file/register-zip-url`;
        const body = {
            type,
            registerIds: registers,
            startDate: hasDateFilter ? filter.dateIni : null,
            endDate: hasDateFilter ? filter.dateFin : null,
            propertyUnits: propertyUnits?.map(item => item.id)
        };

        const response = await GetData(url, HTTP_METHODS.POST, body);

        if (response.status) {
            downloadLink.current = response.data.zipUrl;
        }
    };

    return (
        <div className='download-register-files--content'>
            <ToastElement ref={toastManagerRef} />
            {disableButton && <LoaderElement />}
            {style === 'button' ? (
                <button
                    className='button button-body--register-download'
                    onClick={() => handleButton()}
                    disabled={disableButton}
                >
                    Descargar archivos de registros
                </button>
            ) : (
                <div className='download-register-files--content--text-button'>
                    {(userData.roles[0].name === 'BZero Admin' ||
                        userData.roles[0].name === 'BZero') && (
                        <a
                            href='#'
                            onClick={e => {
                                e.preventDefault();
                                downloadFiles('massive-upload');
                            }}
                        >
                            <div className='download-register-files--content--text-button--image'>
                                <FileExcelFilled />
                            </div>
                            <div className='download-register-files--content--text-button--text'>
                                Descargar datos en Excel
                            </div>
                        </a>
                    )}
                    <a
                        href='#'
                        onClick={e => {
                            e.preventDefault();
                            downloadFiles('photo');
                        }}
                    >
                        <div className='download-register-files--content--text-button--image'>
                            <PictureFilled />
                        </div>
                        <div className='download-register-files--content--text-button--text'>
                            Descargar datos fotográficos
                        </div>
                    </a>
                    <a
                        href='#'
                        onClick={e => {
                            e.preventDefault();
                            downloadFiles('ticket-backup');
                        }}
                    >
                        <div className='download-register-files--content--text-button--image'>
                            <CheckCircleFilled />
                        </div>
                        <div className='download-register-files--content--text-button--text'>
                            Descargar documentos de respaldo de registro
                        </div>
                    </a>
                </div>
            )}

            {showPopUp && (
                <div className='popUp'>
                    <div className='popUp__main'>
                        <div className='popUp__main__container'>
                            <div className='popUp__main__title title'>
                                <span> {title} </span>
                                <CloseIcon
                                    className='popUp__main__title__close'
                                    onClick={() => {
                                        setShowPopUp(false);
                                    }}
                                />
                                <hr />
                            </div>
                            <div className='popUp__main__text subtitle'>
                                {hasDateFilter && (
                                    <div className='popUp__main__text__date-picker'>
                                        <div className='popUp__main__text__date-picker__title'>
                                            Seleccione una fecha:
                                        </div>
                                        <ConfigProvider
                                            locale={esES}
                                            theme={{
                                                components: {
                                                    DatePicker: {
                                                        colorLink: '#a2a2a3',
                                                        colorLinkActive:
                                                            '#a2a2a3',
                                                        colorPrimary: '#a2a2a3',
                                                        colorPrimaryBorder:
                                                            '#a2a2a3',
                                                        colorPrimaryHover:
                                                            '#a2a2a3',
                                                        colorBgContainer:
                                                            '#f3f2f5',
                                                        colorBorder: '#fff',
                                                        fontFamily: 'Roboto',
                                                        colorIcon: '#a2a2a3',
                                                        colorText: '#C4C3C5',
                                                        colorTextHeading:
                                                            '#a2a2a3',
                                                        colorTextPlaceholder:
                                                            '#a2a2a3'
                                                    }
                                                }
                                            }}
                                        >
                                            <DatePicker
                                                onChange={changeDate}
                                                picker='month'
                                                disabledDate={current =>
                                                    current?.isAfter(
                                                        dayjs(),
                                                        'day'
                                                    )
                                                }
                                                defaultValue={dayjs(
                                                    filter.dateIni,
                                                    'YYYY-MM-DD'
                                                )}
                                            />
                                        </ConfigProvider>
                                    </div>
                                )}
                                <div className='popUp__main__text__date-picker'>
                                    <div className='popUp__main__text__date-picker__title'>
                                        Seleccione tipo archivo por descargar:
                                    </div>
                                    <ConfigProvider
                                        theme={{
                                            components: {
                                                Cascader: {
                                                    colorBgContainer: '#f3f2f5',
                                                    colorBgContainerDisabled:
                                                        '#f3f2f5',
                                                    controlItemBgHover:
                                                        '#f3f2f5',
                                                    colorBgElevated: '#f3f2f5'
                                                }
                                            }
                                        }}
                                    >
                                        <Cascader
                                            className='subtitle grey'
                                            size='middle'
                                            style={{ width: '150px' }}
                                            value={[filter.fileType]}
                                            options={[
                                                // only bzero users can download with massive upload
                                                ...(userData.roles[0].name ===
                                                    'BZero Admin' ||
                                                userData.roles[0].name ===
                                                    'BZero'
                                                    ? [
                                                          {
                                                              value: 'massive-upload',
                                                              label: 'massive-upload'
                                                          }
                                                      ]
                                                    : []),
                                                {
                                                    value: 'ticket-backup',
                                                    label: 'ticket-backup'
                                                },
                                                {
                                                    value: 'photo',
                                                    label: 'photo'
                                                }
                                            ]}
                                            onChange={selectedValue => {
                                                setFilter({
                                                    ...filter,
                                                    fileType: selectedValue
                                                        ? selectedValue[0]
                                                        : ''
                                                });
                                            }}
                                        />
                                    </ConfigProvider>
                                </div>
                            </div>
                        </div>
                        <div className='popUp__main__buttons app-d-flex__center childs_uniform'>
                            <button
                                className={`button button-body--register-download ${
                                    disableButton && 'disabled'
                                }`}
                                onClick={e => {
                                    e.preventDefault();
                                    if (filter.fileType) {
                                        downloadFiles(filter.fileType);
                                    } else {
                                        ConfigToast.text =
                                            'Debes seleccionar el tipo de los archivos a descargar';
                                        ConfigToast.backColor = Colors.Error;
                                        ConfigToast.title = 'Error';
                                        setConfigToast(ConfigToast);
                                    }
                                }}
                            >
                                {disableButton ? 'Cargando...' : 'Descargar'}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
