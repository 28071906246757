import '../settings.scss';
//  <--COMPONENTS--> //
import SubNavBar from '../../../components/layout/main/subnavbar/subnavbar';
import Header from '../../../components/header/header';
import SupplyRequirementTableComponent from './supply-requirement-table';
import ButtonBody from '../../../components/button/button';
import { lang } from '../../langs';
//  <--HOOKS--> //
import { useCallApi } from '../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../config/hooks/useCallApi/constants';
//  <--REDUX--> //
import { UserStoreInterface } from '../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../store/hooks';
import { useLocation, useNavigate, useParams } from 'react-router';
import SupplyRequirementCreate from './create/create-edit';
import { useEffect, useState } from 'react';
//  <--SERVICES--> //
import {
    ApiSupplyRequirementId,
    ApiSupplyVisibleRequirements
} from '../../../config/service';
//  <--INTERFACE--> //
import { SupplyRequirementInterface } from '../../../interface';
import { useToast } from '../../../config/hooks/useToast';

const SupplyRequirement = () => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const {
        [defaultLang as keyof typeof lang]: {
            pageSupplyRequirement: { title, newTitle }
        }
    } = lang;
    const { id } = useParams();
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const location = useLocation();
    const isCreateMode = location.pathname.endsWith('/create');
    const { ConfigToast, setConfigToast, Colors } = useToast();

    const [dataList, setDataList] = useState<SupplyRequirementInterface[]>([]);
    const [entity, setEntity] = useState<SupplyRequirementInterface | null>(
        null
    );
    const navigate = useNavigate();

    useEffect(() => {
        const CallData = async () => {
            const response = await GetData(
                ApiSupplyVisibleRequirements,
                HTTP_METHODS.GET
            );
            const { data, status, message } = response;
            setDataList([...data].sort((a, b) => a.name.localeCompare(b.name)));

            if (!status) {
                ConfigToast.text = message;
                ConfigToast.backColor = Colors.Error;
                ConfigToast.title = 'Error';
                setConfigToast(ConfigToast);
            }
        };
        CallData();
    }, []);

    useEffect(() => {
        if (!id) {
            setEntity(null);
            return;
        }

        // dataList has less information than entity
        const CallData = async () => {
            const response = await GetData(
                ApiSupplyRequirementId(id),
                HTTP_METHODS.GET
            );
            const { data, status } = response;
            setEntity(data);

            if (!status) {
                navigate('/404');
            }
        };

        CallData();
    }, [id]);

    const onDelete = requirementId => {
        const index = dataList.findIndex(item => item.id === requirementId);
        if (index !== -1) {
            const updatedDataList = dataList.filter(
                item => item.id !== requirementId
            );
            setDataList(updatedDataList);
        }
    };

    const onUpdate = newEntity => {
        setEntity(newEntity);

        const index = dataList.findIndex(item => item.id === newEntity.id);
        if (index === -1) {
            dataList.push(newEntity);
        } else {
            dataList[index] = newEntity;
        }
        setDataList([...dataList].sort((a, b) => a.name.localeCompare(b.name)));
    };

    return (
        <>
            {LoadingData && <LoaderElement />}
            <SubNavBar
                type='settings'
                title={title}
                icon='settings/supplyIconB.svg'
                kind='settings'
                option='SupplyRequirements'
            ></SubNavBar>

            <div className='settings'>
                <Header
                    title={{ name: title, link: '/panel/supply-requirements' }}
                    icon='DataIconB.svg'
                    subtitle={
                        entity
                            ? {
                                  name: entity?.name ?? '',
                                  link: `/panel/supply-requirements/edit/${id}`
                              }
                            : undefined
                    }
                />
                <div className='settings__grid-container'>
                    <div className='settings__grid-container__item'>
                        <SupplyRequirementTableComponent items={dataList} />
                        <div className='settings__grid-container__item__content'>
                            <ButtonBody
                                able='true'
                                kind='button button-body--create'
                                text={newTitle}
                                path={`/panel/supply-requirements/create`}
                            ></ButtonBody>
                        </div>
                    </div>

                    {id ? (
                        <SupplyRequirementCreate
                            entity={entity}
                            onDelete={onDelete}
                            onUpdate={onUpdate}
                        />
                    ) : (
                        isCreateMode && (
                            <SupplyRequirementCreate
                                entity={null}
                                onDelete={onDelete}
                                onUpdate={onUpdate}
                            />
                        )
                    )}
                </div>
            </div>
        </>
    );
};

export default SupplyRequirement;
