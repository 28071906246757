export const lang = {
    es: {
        page404: {
            title: 'Página no encontrada',
            description: 'La página que buscas no se encuentra disponible.',
            detail: 'Es posible que haya escrito mal la dirección o que la página se haya movido.',
            button: 'Ir a la página de inicio'
        },
        pageAppSection: {
            title: 'Secciones',
            buttonNew: 'Crear Sección',
            createTitle: 'Crear Sección',
            editTitle: 'Editar Sección',
            deleteMessage: 'Desea eliminar este registro?',
            errorMessage: 'Ocurrió un error'
        },
        pageBzeroReports: {
            title: 'Reportes BZero'
        },
        pageData: {
            title: 'Datos',
            option1: 'Tipologías',
            option2: 'Fuentes',
            typology: {
                newTitle: 'Nueva Tipología',
                createTitle: 'Crear tipología',
                editTitle: 'Editar tipología',
                detailTitle: 'Detalle de la tipología',
                adminTitle: 'Administrar tipología',
                deleteMessage: 'Desea eliminar este registro?',
                deleteTitle: 'Eliminar tipología'
            },
            font: {
                newTitle: 'Nueva fuente',
                createTitle: 'Crear fuente',
                editTitle: 'Editar fuente',
                detailTitle: 'Detalle de la fuente',
                adminTitle: 'Administrar fuente',
                deleteMessage: 'Desea eliminar este registro?',
                deleteTitle: 'Eliminar fuente',
                uploadDataTitle: 'Cargar datos'
            },
            requirement: {
                newTitle: 'Nuevo requerimiento',
                createTitle: 'Crear requerimiento',
                editTitle: 'Editar requerimiento',
                detailTitle: 'Detalle del requerimiento',
                adminTitle: 'Administrar requerimiento',
                deleteMessage: 'Desea eliminar este registro?',
                deleteTitle: 'Eliminar requerimiento'
            }
        },
        pageDataCenter: {
            monthlyIntegratedEvolution: 'Evolución Integrada Mensual',
            evolutionButton: 'Ver detalle',
            subtitle: 'Periodo: últimos 12 meses',
            months: [
                'Enero',
                'Febrero',
                'Marzo',
                'Abril',
                'Mayo',
                'Junio',
                'Julio',
                'Agosto',
                'Septiembre',
                'Octubre',
                'Noviembre',
                'Diciembre'
            ],
            pageEvolution: {
                title: 'Total integral de reciclables',
                downloadDataButton: 'Descargar datos',
                downloadChartButton: 'Descargar gráfico',
                goBack: 'Volver',
                dateRangeTitle: 'Rango de fechas a visualizar',
                viewDetailsText: 'Ver detalle de registros de este reporte'
            },
            pageGarbageControl: {
                title: 'Control de bolsas de basura',
                dateRangeTitle: 'Rango de fechas a visualizar'
            },
            pageRanking: {
                title: 'Ranking inmuebles'
            }
        },
        pageGestor: {
            title: 'Gestores',
            buttonNew: 'Crear Gestor',
            createTitle: 'Crear Gestor',
            editTitle: 'Editar Gestor',
            deleteMessage: 'Desea eliminar este registro?',
            adminTitle: 'Administrar gestores',
            newTitle: 'Nuevo Gestor',
            detailTitle: 'Detalle de Gestor',
            deleteTitle: 'Eliminar Gestor',
            trucksTitle: 'Camiones'
        },
        pageInvites: {
            title: 'Usuarios',
            subtitle: 'Invitaciones',
            buttonNew: 'Invitar usuario',
            createTitle: 'Invitar Usuario',
            editTitle: '',
            deleteMessage: 'Desea eliminar este registro?',
            buttonSendInvitation: 'Enviar invitación',
            adminTitle: 'Administrar invitaciones',
            deleteTitle: 'Eliminar invitación',
            copyTitle: 'Copiar invitación',
            detailTitle: 'Detalle de Invitación',
            copyMessage: 'Copiado en portapapeles'
        },
        pageLogin: {
            title: 'Iniciar sesión',
            subTitle:
                'Bienvenido a la plataforma de administración de tu proyecto basura cero',
            btnLoginTxt: 'Ingresar',
            btnCreateUserText: 'Crear usuario',
            errorNotInvitation: 'Debe tener una invitación',
            forgotPassword: 'olvidé mi contraseña',
            recordsTitle: 'Registros'
        },
        pageMaterial: {
            title: 'Materiales',
            buttonNew: 'Crear Material',
            createTitle: 'Crear material',
            editTitle: 'Editar material',
            deleteMessage: 'Desea eliminar este registro?',
            adminTitle: 'Administrar por:',
            option1: 'Materiales',
            option2: 'Clases de Materiales',
            newTitle: 'Nuevo material',
            detailTitle: 'Detalle de Material',
            deleteTitle: 'Eliminar Material'
        },
        pageMaterialKind: {
            title: 'Clases de Materiales',
            buttonNew: 'Crear clase de material',
            detailsTitle: 'Materiales asociados',
            createTitle: 'Crear clase de material',
            editTitle: 'Editar clase de material',
            deleteMessage:
                'Esta acción eliminará los materiales asociados, desea eliminar este registro?'
        },
        pageMyProfile: {
            profile: {
                title: 'Mi perfil',
                termsAndConditions: 'Términos de Servicio',
                privacyPolicy: 'Política de Privacidad',
                exportUserData: 'Exportar datos del usuario',
                clearMyData: 'Borrar todos mis datos',
                editButton: 'Editar datos personales',
                languageButton: 'Preferencias de idioma',
                changePassButton: 'Cambiar contraseña',
                changePassBtnText: 'Cambiar contraseña',
                updateProfileBtnText: 'Editar perfil',
                notificationBtnText: 'Configurar notificaciones'
            },
            editProfile: {
                title: 'Datos Personales',
                lblImage: 'Editar imagen',
                imageButonText: 'Subir imagen',
                lblNotification: 'Notificaciones',
                mailNotification: 'Recibir al mail',
                smsNotification: 'Recibir por SMS',
                lblAlarms: 'Alarmas',
                soundAlarm: 'Alarmas de sonido',
                calendarSync: 'Sincronizar con google calendar',
                successMessage: 'Datos modificados',
                errorMessage: 'Ocurrió un error'
            },
            changePass: {
                title: 'Cambio de Contraseña',
                errorNewPasswords: 'Las contraseñas nuevas no coinciden',
                successMessage: 'Datos modificados',
                errorMessage: 'Ocurrió un error'
            },
            langPreferences: {
                title: 'Preferencias de idioma'
            }
        },
        pagePassword: {
            notExistRpt: 'No existe esta solicitud',
            greeting: 'Hola',
            postGreeting: ', vamos a cambiar tu contraseña.',
            recoveryTitle: 'Recuperar contraseña',
            errorPassvalidate: 'Las contraseñas no coinciden'
        },
        pageProfileSelection: {
            title: 'Selección de perfil',
            buttonText: 'Establecer por defecto'
        },
        pageProgram: {
            title: 'Programa',
            buttonNew: 'Crear Programa',
            detailsTitle: 'Materiales asociados',
            createTitle: 'Crear Programa',
            editTitle: 'Editar Programa',
            deleteMessage:
                'Esta acción eliminará los materiales asociados, desea eliminar este registro?'
        },
        pageProperty: {
            title: 'Inmuebles',
            buttonNew: 'Crear Inmueble',
            detailsTitle: 'Materiales asociados',
            detailPropertyCompany: 'Compañías asociadas',
            createTitle: 'Crear Inmueble',
            editConfigButton: 'Editar',
            deleteMessage:
                'Esta acción eliminará los materiales asociados, desea eliminar este registro?',
            adminTitle: 'Administrar inmuebles',
            newTitle: 'Nuevo inmueble',
            detailTitle: 'Detalle de Inmueble',
            deleteTitle: 'Eliminar Inmueble',
            configTitle: 'Configuración de Reportes',
            editTitle: 'Editar Inmueble'
        },
        pagePropertyUnit: {
            propertyUnitTitle: 'Unidades',
            adminTitle: 'Administrar unidades',
            newTitle: 'Nueva unidad',
            createTitle: 'Crear unidad',
            deleteTitle: 'Eliminar unidad',
            editTitle: 'Editar unidad',
            deleteMessage: '¿Desea eliminar este registro?',
            detailTitle: 'Detalle de la unidad'
        },
        pageRegister: {
            createTitle: 'Registro de usuario',
            errorPassvalidate: 'Las contraseñas no coinciden',
            title: 'Datos Personales',
            subTitle:
                'Bienvenido a la plataforma de administración de tu proyecto basura cero',
            loginMessage: '¿Ya tienes una cuenta?',
            login: 'Inicia Sesión'
        },
        pageRecords: {
            title: 'Registros'
        },
        pageRole: {
            title: 'Roles',
            buttonNew: 'Crear Rol',
            createTitle: 'Crear Rol',
            editTitle: 'Editar Rol',
            deleteMessage: 'Desea eliminar este registro?',
            errorMessage: 'Ocurrió un error',
            accessTitle: 'Accesos asociados',
            asociatedSections: 'Secciones asociadas',
            unasociatedSections: 'Secciones sin asociar',
            newTitle: 'Crear rol',
            adminTitle: 'Administrar roles',
            detailTitle: 'Detalle de rol',
            deleteTitle: 'Eliminar Rol'
        },
        pageSupply: {
            title: 'Insumos',
            buttonNew: 'Crear insumo',
            createTitle: 'Crear insumo',
            editTitle: 'Editar insumo',
            deleteMessage: 'Desea eliminar este registro?',
            newTitle: 'Nuevo insumo',
            detailTitle: 'Detalle de insumo',
            deleteTitle: 'Eliminar insumo',
            adminTitle: 'Administrar insumo',
            option1: 'Insumos',
            option2: 'Clases de Insumos',
            supplyRequirement: {
                Title: 'Clases de Insumos',
                newTitle: 'Nuevo requerimiento',
                createTitle: 'Crear requerimiento',
                editTitle: 'Editar requerimiento',
                detailTitle: 'Detalle del requerimiento',
                adminTitle: 'Administrar requerimiento',
                deleteMessage: 'Desea eliminar este registro?',
                deleteTitle: 'Eliminar requerimiento'
            }
        },
        pageSupplyKind: {
            createTitle: 'Crear clase de insumo',
            editTitle: 'Editar clase de insumo'
        },
        pageSupplyRequirement: {
            title: 'Requerimientos',
            newTitle: 'Nuevo Requerimiento',
            supplyRequirement: {
                newTitle: 'Nuevo requerimiento',
                createTitle: 'Crear requerimiento',
                editTitle: 'Editar requerimiento',
                detailTitle: 'Detalle del requerimiento',
                adminTitle: 'Administrar requerimiento',
                deleteMessage: 'Desea eliminar este registro?',
                deleteTitle: 'Eliminar requerimiento'
            },
            invisible: {
                subtitle: 'test'
            }
        },
        pageTruck: {
            trucksTitle: 'Camiones',
            buttonNew: 'Crear Camión',
            createTitle: 'Crear Camión',
            editTitle: 'Editar Camión',
            deleteMessage: 'Desea eliminar este registro?',
            adminTitle: 'Administrar camiones',
            newTitle: 'Nuevo camión',
            deleteTitle: 'Eliminar camión',
            detailTitle: 'Detalle del camión'
        },
        pageUser: {
            title: 'Usuarios',
            buttonNew: 'Crear Usuario',
            createTitle: 'Crear Usuario',
            adminTitle: 'Administrar usuarios',
            editTitle: 'Editar Usuario',
            deleteMessage: 'Desea eliminar este registro?',
            detailTitle: 'Detalle de Usuario',
            inviteTitle: 'Invitaciones',
            deleteTitle: 'Eliminar Usuario',
            rolesTitle: 'Roles'
        },
        pageClient: {
            title: 'Clientes',
            buttonNew: 'Crear Cliente',
            createTitle: 'Crear Cliente',
            adminTitle: 'Administrar clientes',
            editTitle: 'Editar Cliente',
            deleteMessage: 'Desea eliminar este registro?',
            detailTitle: 'Detalle de Cliente',
            deleteTitle: 'Eliminar Cliente',
            usersBtn: 'Ver usuarios',
            propertiesBtn: 'Ver inmuebles',
            uploadBtn: 'Subir',
            instructionsTitle:
                'Clik en iconos Inmuebles/Unidades para cambiar selección',
            cancelBtn: 'Cancelar',
            saveBtn: 'Guardar',
            previewTitle: 'Previsualización',
            previewSubtitle: 'Mostrar elementos de solo visualización',
            legendTitle: 'Herramientas de edición',
            crossLegend: 'Inmueble / Unidad deshabilitado',
            checkLegend: 'Inmueble / Unidad habilitado',
            eyeLegend: 'Inmueble / Unidad solo para visualización'
        },
        pageQR: {
            title: 'Códigos QR',
            buttonNew: 'Crear Código QR',
            createTitle: 'Crear Código QR',
            editTitle: 'Editar Código QR',
            deleteMessage: 'Desea eliminar este registro?',
            adminTitle: 'Administrar Códigos QR',
            newTitle: 'Nuevo Código QR',
            detailTitle: 'Detalle de Código QR',
            deleteTitle: 'Eliminar Código QR',
            downloadTitle: 'Descargar Código QR',
            copyQRLink: 'Copiar enlace'
        },
        controlPanel: {
            title: 'Panel de control'
        },
        montlyClosure: {
            title: 'Cierre mensual'
        },
        sinader: {
            autocompleteArray: {
                title: 'Tabla de cierre mensual',
                downloadButton: 'Descargar datos',
                autocompleteButton: 'Autocompletar',
                saveButton: 'Guardar',
                closeMonthButton: 'Cerrar mes',
                closeMessage: '¿Confirma realizar el cierre de este mes?',
                closeWarning:
                    'Una vez cerrado no se podrán modificar los campos',
                legend: {
                    primaryLight: 'Información autocompletada ya guardada',
                    secondaryLight: 'Información editada ya guardada',
                    primary: 'Información autocompletada no guardada',
                    secondary: 'Información editada no guardada'
                },
                uploadButton: 'Carga masiva',
                months: [
                    'Enero',
                    'Febrero',
                    'Marzo',
                    'Abril',
                    'Mayo',
                    'Junio',
                    'Julio',
                    'Agosto',
                    'Septiembre',
                    'Octubre',
                    'Noviembre',
                    'Diciembre'
                ],
                confirmTitle: 'Confirmar autocompletado',
                confirmMessage: 'Desea autocompletar los datos de la planilla?',
                masiveTitle: 'Carga masiva',
                loadTitle: 'Cargar archivo',
                errorTitle: 'Error',
                errorMessage: 'Archivo no cargado',
                downloadTemplate: 'Descargar plantilla de ejemplo aquí',
                selectBtn: 'Seleccionar archivo',
                fileSelectedTitle: 'Archivo seleccionado'
            },
            defaultValues: {
                title: 'Matriz de autocompletado',
                downloadButton: 'Descargar planilla',
                saveButton: 'Guardar cambios'
            }
        }
    },
    en: {
        page404: {
            title: 'Page not found',
            description: 'The page you are looking for is not available.',
            detail: 'You may have misspelled the address or the page may have moved.',
            button: 'Go to home page'
        },
        pageAppSection: {
            title: 'Sections',
            buttonNew: 'Create Section',
            createTitle: 'Create Section',
            editTitle: 'Edit Section',
            deleteMessage: 'Do you want to delete this record?',
            errorMessage: 'An error has ocurred'
        },
        pageBzeroReports: {
            title: 'BZero reports'
        },
        pageData: {
            title: 'Data fonts',
            option1: 'Typologies',
            option2: 'Fonts',
            typology: {
                newTitle: 'New typology',
                createTitle: 'Crete typology',
                editTitle: 'Edit typology',
                detailTitle: 'Typology detail',
                adminTitle: 'Manage typology',
                deleteMessage: 'Do you want to delete this record?',
                deleteTitle: 'Delete typology'
            },
            font: {
                newTitle: 'New font',
                createTitle: 'Crete font',
                editTitle: 'Edit font',
                detailTitle: 'Font detail',
                adminTitle: 'Manage font',
                deleteMessage: 'Do you want to delete this record?',
                deleteTitle: 'Delete font',
                uploadDataTitle: 'Upload data'
            },
            requirement: {
                newTitle: 'New requirement',
                createTitle: 'Crete requirement',
                editTitle: 'Edit requirement',
                detailTitle: 'Requirement detail',
                adminTitle: 'Manage requirement',
                deleteMessage: 'Do you want to delete this record?',
                deleteTitle: 'Delete requirement'
            }
        },
        pageDataCenter: {
            monthlyIntegratedEvolution: 'Evolución Integrada Mensual',
            evolutionButton: 'View details',
            subtitle: 'Period: last 12 months',
            months: [
                'January',
                'February',
                'March',
                'April',
                'Mao',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December'
            ],
            pageEvolution: {
                title: 'Integrated monthly evolution',
                downloadDataButton: 'Download data',
                downloadChartButton: 'Download chart',
                goBack: 'Go back',
                dateRangeTitle: 'Date range showing',
                viewDetailsText: 'View registers details from this report'
            },
            pageGarbageControl: {
                title: 'Garbage bags control',
                dateRangeTitle: 'Date range showing'
            },
            pageRanking: {
                title: 'Properties ranking'
            }
        },
        pageGestor: {
            title: 'Gestors',
            buttonNew: 'Create Gestor',
            createTitle: 'Create Gestor',
            editTitle: 'Edit Gestor',
            deleteMessage: 'Do you want to delete this record?',
            adminTitle: 'Manage gestors',
            newTitle: 'New Gestor',
            detailTitle: 'Gestor Detail',
            deleteTitle: 'Delete Gestor',
            trucksTitle: 'Trucks'
        },
        pageInvites: {
            title: 'Users',
            subtitle: 'Invitations',
            buttonNew: 'Invite User',
            createTitle: 'Invite User',
            editTitle: '',
            deleteMessage: 'Do you want to delete this record?',
            buttonSendInvitation: 'Send invitation',
            adminTitle: 'Manage invitations',
            deleteTitle: 'Delete invitation',
            copyTitle: 'Copy invitation',
            detailTitle: 'Invitation Detail',
            copyMessage: 'Copied to clipboard'
        },
        pageLogin: {
            title: 'Log in',
            subTitle:
                'Welcome to the management platform for your zero waste project',
            btnLoginTxt: 'Log in',
            btnCreateUserText: 'Create user',
            errorNotInvitation: 'You must have an invitation',
            forgotPassword: 'forgot my password',
            recordsTitle: 'Records'
        },
        pageMaterial: {
            title: 'Materials',
            buttonNew: 'Create Material',
            createTitle: 'Create Material',
            editTitle: 'Edit Material',
            deleteMessage: 'Do you want to delete this record?',
            adminTitle: 'Manage by:',
            option1: 'Materials',
            option2: 'Material Type',
            newTitle: 'New material',
            detailTitle: 'Material Detail',
            deleteTitle: 'Delete Material'
        },
        pageMaterialKind: {
            title: 'Material Kind',
            buttonNew: 'Create Material Kind',
            detailsTitle: 'Associated Materials',
            createTitle: 'Create Material Kind',
            editTitle: 'Edit Material Kind',
            deleteMessage:
                'This action will delete the associated materials, do you want to delete this record?'
        },
        pageMyProfile: {
            profile: {
                title: 'My profile',
                termsAndConditions: 'Terms of service',
                privacyPolicy: 'Privacy policy',
                exportUserData: 'Export user data',
                clearMyData: 'Clear my data',
                editButton: 'Edit personal data',
                languageButton: 'Languaje preferences',
                changePassButton: 'Change password',
                changePassBtnText: 'Change password',
                updateProfileBtnText: 'Edit profile',
                notificationBtnText: 'Configure notifications'
            },
            editProfile: {
                title: 'Personal data',
                lblImage: 'Edit image',
                imageButonText: 'Upload image',
                lblNotification: 'Notifications',
                mailNotification: 'Send to email',
                smsNotification: 'Send by SMS',
                lblAlarms: 'Alarms',
                soundAlarm: 'Sound alarms',
                calendarSync: 'Sincronize with google calendar',
                successMessage: 'Register updated',
                errorMessage: 'An error has ocurred'
            },
            changePass: {
                title: 'Change password',
                errorNewPasswords: 'The new password does not match',
                successMessage: 'Register updated',
                errorMessage: 'An error has ocurred'
            },
            langPreferences: {
                title: 'Preferencias de idioma'
            }
        },
        pagePassword: {
            notExistRpt: 'This request does not exist',
            greeting: 'Hello',
            postGreeting: `, let's change your password.`,
            recoveryTitle: 'Reciver password',
            errorPassvalidate: 'Passwords do not match'
        },
        pageProfileSelection: {
            title: 'Profile Selection',
            buttonText: 'Set as deffault'
        },
        pageProgram: {
            title: 'Program',
            buttonNew: 'Create Program',
            detailsTitle: 'Associated Materials',
            createTitle: 'Create Program',
            editTitle: 'Edit Program',
            deleteMessage:
                'This action will delete the associated materials, do you want to delete this record?'
        },
        pageProperty: {
            title: 'Properties',
            buttonNew: 'Create Properties',
            detailsTitle: 'Associated Materials',
            detailPropertyCompany: 'Associated companies',
            createTitle: 'Create Properties',
            editConfigButton: 'Edit',
            deleteMessage:
                'This action will delete the associated materials, do you want to delete this record?',
            adminTitle: 'Manage property',
            newTitle: 'New property',
            detailTitle: 'Property Detail',
            deleteTitle: 'Delete Property',
            configTitle: 'Reports settings',
            editTitle: 'Edit property'
        },
        pagePropertyUnit: {
            propertyUnitTitle: 'Units',
            adminTitle: 'Manage units',
            newTitle: 'New unit',
            createTitle: 'Create unit',
            deleteTitle: 'Delete unit',
            editTitle: 'Editar unidad',
            deleteMessage: 'Do you want to delete this record?',
            detailTitle: 'Unit detail'
        },
        pageRegister: {
            createTitle: 'User registration',
            errorPassvalidate: "Passwords don't match",
            title: 'Personal',
            subTitle:
                'Welcome to the management platform for your zero waste project',
            loginMessage: 'You already have an account?',
            login: 'Log In'
        },
        pageRecords: {
            title: 'Records'
        },
        pageRole: {
            title: 'Role',
            buttonNew: 'Create Role',
            createTitle: 'Create Role',
            editTitle: 'Edit Role',
            deleteMessage: 'Do you want to delete this record?',
            errorMessage: 'An error has ocurred',
            accessTitle: 'Associated accesses',
            asociatedSections: 'Associated sections',
            unasociatedSections: 'Unassociated sections',
            newTitle: 'Create role',
            adminTitle: 'Manage roles',
            detailTitle: 'Role detail',
            deleteTitle: 'Delete Role'
        },

        pageSupply: {
            title: 'Supply',
            buttonNew: 'Create supply',
            createTitle: 'Create supply',
            editTitle: 'Edit supply',
            deleteMessage: 'Do you want to delete this record?',
            newTitle: 'New supply',
            detailTitle: 'Supply detail',
            deleteTitle: 'Delete supply',
            adminTitle: 'Manage supply',
            option1: 'Supplies',
            option2: 'Supplies kinds',
            supplyRequirement: {
                title: 'Supplies kinds',
                newTitle: 'New requirement',
                createTitle: 'Crete requirement',
                editTitle: 'Edit requirement',
                detailTitle: 'Requirement detail',
                adminTitle: 'Manage requirement',
                deleteMessage: 'Do you want to delete this record?',
                deleteTitle: 'Delete requirement'
            }
        },
        pageSupplyKind: {
            createTitle: 'Create supply kind',
            editTitle: 'Edit supply kind'
        },
        pageSupplyRequirement: {
            title: 'Requirements',
            newTitle: 'New requirement',
            supplyRequirement: {
                newTitle: 'New requirement',
                createTitle: 'Crete requirement',
                editTitle: 'Edit requirement',
                detailTitle: 'Requirement detail',
                adminTitle: 'Manage requirement',
                deleteMessage: 'Do you want to delete this record?',
                deleteTitle: 'Delete requirement'
            },
            invisible: {
                subtitle: 'test'
            }
        },
        pageTruck: {
            trucksTitle: 'Trucks',
            buttonNew: 'Create Truck',
            createTitle: 'Create Truck',
            editTitle: 'Edit Truck',
            deleteMessage: 'Do you want to delete this record?',
            adminTitle: 'Manage trucks',
            newTitle: 'New truck',
            deleteTitle: 'Delete truck',
            detailTitle: 'Truck detail'
        },
        pageUser: {
            title: 'Usuarios',
            buttonNew: 'Create User',
            createTitle: 'Create User',
            adminTitle: 'Manage users',
            editTitle: 'Edit User',
            deleteMessage: 'Do you want to delete this record?',
            detailTitle: 'User Detail',
            inviteTitle: 'Invitations',
            deleteTitle: 'Delete User',
            rolesTitle: 'Roles'
        },
        pageClient: {
            title: 'Clients',
            buttonNew: 'Create Client',
            createTitle: 'Create Client',
            adminTitle: 'Manage clients',
            editTitle: 'Edit Client',
            deleteMessage: 'Do you want to delete this record??',
            detailTitle: 'Client details',
            deleteTitle: 'Delete Client',
            usersBtn: 'See users',
            propertiesBtn: 'See properties',
            uploadBtn: 'Upload',
            instructionsTitle:
                'Click on Property/Unit icons to change selection',
            cancelBtn: 'Cancel',
            saveBtn: 'Save',
            previewTitle: 'Preview',
            previewSubtitle: 'Show read only elements',
            legendTitle: 'Edition tools',
            crossLegend: 'Property/Unit disabled',
            checkLegend: 'Property/Unit enabled',
            eyeLegend: 'Property/Unit for display only'
        },
        pageQR: {
            title: 'QR Codes',
            buttonNew: 'Create QR Code',
            createTitle: 'Create QR Code',
            editTitle: 'Edit QR Code',
            deleteMessage: 'Do you want to delete this record?',
            adminTitle: 'Manage QR Codes',
            newTitle: 'New QR Code',
            detailTitle: 'QR Code Detail',
            deleteTitle: 'Delete QR Code',
            downloadTitle: 'Download QR Code',
            copyQRLink: 'Copy link'
        },
        controlPanel: {
            title: 'Control panel'
        },
        montlyClosure: {
            title: 'Montly closure'
        },
        sinader: {
            autocompleteArray: {
                title: 'Monthly closing table',
                downloadButton: 'Download data',
                autocompleteButton: 'Autocomplete',
                saveButton: 'Save',
                closeMonthButton: 'Close month',
                closeMessage: 'Are you sure you want to close this month?',
                closeWarning: 'Once closed, the fields cannot be modified',
                legend: {
                    primaryLight: 'Autocompleted information already saved',
                    secondaryLight: 'Edited information already saved',
                    primary: 'Autocompleted information not saved',
                    secondary: 'Edited information not saved'
                },
                uploadButton: 'Upload',
                months: [
                    'January',
                    'February',
                    'March',
                    'April',
                    'Mao',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December'
                ],
                confirmTitle: 'Confirm autocomplete',
                confirmMessage: 'Do you want to auto-complete the form data?',
                masiveTitle: 'Bulk data loading',
                loadTitle: 'Load file',
                errorTitle: 'Error',
                errorMessage: "Couldn't load file",
                downloadTemplate: 'Download example template here',
                selectBtn: 'Select file',
                fileSelectedTitle: 'Selected file'
            },
            defaultValues: {
                title: 'Autocomplete matrix',
                downloadButton: 'Download form',
                saveButton: 'Save changes'
            }
        }
    }
};
