import { GetStorage } from '../../../config/utils/Storage';
import { PropertyUnitStoreInterface } from './property-unit.interface';

const CCData = GetStorage(process.env.REACT_APP_PUBLIC_USER_UNITS);
const dataIni = [];

export const PropertyUnitDefault: PropertyUnitStoreInterface = {
    propertyUnitData: CCData || dataIni,
    selectedUnits: []
};
